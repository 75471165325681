import i18n from 'i18next';
import * as _ from 'lodash';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, FeatureTypes, GameMode, IBonus, bonusIds } from '../../global.d';
import { setBetAmount, setBonuses, setCoinAmount, setCurrency, setSlotConfig } from '../../gql/cache';
import { IBetSettings } from '../../gql/d';
import { getBetsSetting } from '../../gql/fromFragment';
import { ResourceTypes } from '../../resources.d';
import { countCoins, formatNumber, isMobileDevice, normalizeCoins, showCurrency, updateTextScale } from '../../utils';
import {
  FEATURE_POPUP_AMOUNT_TEXT_POSITION_X,
  FEATURE_POPUP_AMOUNT_TEXT_POSITION_Y,
  FEATURE_POPUP_BET_VALUE_POSITION_X,
  FEATURE_POPUP_BET_VALUE_POSITION_Y,
  FEATURE_POPUP_CANCEL_BTN_POSITION_X,
  FEATURE_POPUP_CANCEL_BTN_POSITION_Y,
  FEATURE_POPUP_INPUT_HEIGHT,
  FEATURE_POPUP_INPUT_POSITION_X,
  FEATURE_POPUP_INPUT_POSITION_Y,
  FEATURE_POPUP_INPUT_WIDTH,
  FEATURE_POPUP_MINUS_BTN_HEIGHT,
  FEATURE_POPUP_MINUS_BTN_POSITION_X,
  FEATURE_POPUP_MINUS_BTN_POSITION_Y,
  FEATURE_POPUP_MINUS_BTN_WIDTH,
  FEATURE_POPUP_OK_BTN_POSITION_X,
  FEATURE_POPUP_OK_BTN_POSITION_Y,
  FEATURE_POPUP_PLUS_BTN_HEIGHT,
  FEATURE_POPUP_PLUS_BTN_POSITION_X,
  FEATURE_POPUP_PLUS_BTN_POSITION_Y,
  FEATURE_POPUP_PLUS_BTN_WIDTH,
  FEATURE_POPUP_TITLE_POSITION_X,
  FEATURE_POPUP_TITLE_POSITION_Y,
  FEATURE_POPUP_TOTAL_CONST_TEXT_AMOUNT_POSITION_X,
  FEATURE_POPUP_TOTAL_CONST_TEXT_AMOUNT_POSITION_Y,
  FEATURE_POPUP_TOTAL_CONST_TEXT_POSITION_X,
  FEATURE_POPUP_TOTAL_CONST_TEXT_POSITION_Y,
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  eventManager,
} from '../config';

import {
  amountTextStyle,
  betValueStyle,
  buyFeatureTitleStyle,
  totalCostTextAmountStyle,
  totalCostTextStyle,
} from './textStyles';

class BuyFeaturePopup extends PIXI.Container {
  private popupBg: PIXI.Sprite;

  private okBtn: PIXI.Sprite;

  private cancelBtn: PIXI.Sprite;

  private titleText: PIXI.Text;

  private totalCostText: PIXI.Text;

  private totalCostTextAmount: PIXI.Text;

  private amountText: PIXI.Text;

  private minusBtn: PIXI.Sprite;

  private plusBtn: PIXI.Sprite;

  private input: PIXI.Sprite;

  private betSettings: IBetSettings;

  private betAmount: number;

  private linesAmount: number;

  private currency = 'FUN';

  private betValue: PIXI.Text;

  isNoFunds: boolean;

  balance: number;

  constructor(lines: number[][]) {
    super();
    this.betSettings = getBetsSetting();
    this.visible = false;
    this.linesAmount = lines.length;
    this.balance = 0;
    this.isNoFunds = false;
    this.interactive = true;
    this.currency = setCurrency();

    this.betAmount = this.getBetAmount(setBetAmount());
    this.popupBg = this.initPopupBg();
    this.titleText = this.initTitle();
    this.totalCostText = this.initTotalCostText();
    this.input = this.initInput();
    this.totalCostTextAmount = this.initTotalCostTextAmount();
    this.amountText = this.initAmountText();
    this.minusBtn = this.initMinusBtn();
    this.plusBtn = this.initPlusBtn();
    this.betValue = this.initBetValue();
    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();

    this.init();
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => {
      this.closeAllAnimationsInSlot();
      this.visible = true;
    });
    eventManager.on(EventTypes.UPDATE_BET, () => {
      this.betAmount = this.getBetAmount(setBetAmount());
      this.updateBets();
      this.handleDisable();
    });
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, () => {
      this.visible = false;
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP, false);
      eventManager.emit(EventTypes.POPUP_BG_VISIBLE, false);
    });
    eventManager.on(EventTypes.UPDATE_USER_BALANCE, (balance?: { currency: string; amount: number }) => {
      this.balance = balance!.amount / 100;
      this.handleDisable();
    });

    eventManager.on(EventTypes.CLOSE_BUY_FEATURE_POPUP, () => {
      this.visible = false;
    });
  }

  private init(): void {
    this.addChild(this.popupBg);
    this.addChild(this.titleText);
    this.addChild(this.totalCostText);
    this.addChild(this.totalCostTextAmount);
    this.addChild(this.amountText);
    this.addChild(this.minusBtn);
    this.addChild(this.plusBtn);
    this.addChild(this.input);
    this.addChild(this.betValue);
    this.addChild(this.okBtn);
    this.addChild(this.cancelBtn);
  }

  private initPopupBg(): PIXI.Sprite {
    const popupBg = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeaturePopup));
    popupBg.anchor.set(0.5, 0.5);
    popupBg.position.set(GAME_CONTAINER_WIDTH / 2, GAME_CONTAINER_HEIGHT / 2);
    return popupBg;
  }

  private initTitle(): PIXI.Text {
    const title = new PIXI.Text(i18n.t<string>('buyFeatureTitle'), buyFeatureTitleStyle);
    title.resolution = 1;
    title.y = FEATURE_POPUP_TITLE_POSITION_Y;
    title.x = FEATURE_POPUP_TITLE_POSITION_X;
    title.anchor.set(0.5, 0);
    updateTextScale(title, this.popupBg.width - 200, isMobileDevice() ? 450 : 300);
    return title;
  }

  private initTotalCostText(): PIXI.Text {
    const totalCostText = new PIXI.Text(i18n.t<string>('buyFeatureTotalCost'), totalCostTextStyle);
    totalCostText.resolution = 1;
    totalCostText.y = FEATURE_POPUP_TOTAL_CONST_TEXT_POSITION_Y;
    totalCostText.x = FEATURE_POPUP_TOTAL_CONST_TEXT_POSITION_X;
    totalCostText.anchor.set(0.5, 0.5);
    updateTextScale(totalCostText, 500, isMobileDevice() ? 250 : 250);

    return totalCostText;
  }

  private initTotalCostTextAmount(): PIXI.Text {
    const totalCostTextAmount = new PIXI.Text(this.getTotalCost(), totalCostTextAmountStyle);
    totalCostTextAmount.resolution = 1;
    totalCostTextAmount.y = FEATURE_POPUP_TOTAL_CONST_TEXT_AMOUNT_POSITION_Y;
    totalCostTextAmount.x = FEATURE_POPUP_TOTAL_CONST_TEXT_AMOUNT_POSITION_X;
    totalCostTextAmount.anchor.set(0.5, 0.5);
    updateTextScale(totalCostTextAmount, isMobileDevice() ? 350 : 450, isMobileDevice() ? 250 : 250);

    return totalCostTextAmount;
  }

  private initAmountText(): PIXI.Text {
    const amountText = new PIXI.Text(i18n.t<string>('buyFeatureBetPerGame'), amountTextStyle);
    amountText.resolution = 1;
    amountText.y = FEATURE_POPUP_AMOUNT_TEXT_POSITION_Y;
    amountText.x = FEATURE_POPUP_AMOUNT_TEXT_POSITION_X;
    amountText.anchor.set(0.5, 0);
    updateTextScale(amountText, 250, 250);

    return amountText;
  }

  private initMinusBtn(): PIXI.Sprite {
    const minusBtn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtn));
    minusBtn.width = FEATURE_POPUP_MINUS_BTN_WIDTH;
    minusBtn.height = FEATURE_POPUP_MINUS_BTN_HEIGHT;
    minusBtn.y = FEATURE_POPUP_MINUS_BTN_POSITION_Y;
    minusBtn.x = FEATURE_POPUP_MINUS_BTN_POSITION_X;
    minusBtn.interactive = true;
    minusBtn.buttonMode = true;
    minusBtn.anchor.set(0.5, 0);
    minusBtn.on('click', this.handleMinus);
    minusBtn.on('touchstart', this.handleMinus);

    minusBtn.addListener('mouseover', () => {
      if (this.betAmount > 0 && this.betSettings.bets[this.betAmount - 1]! > this.betSettings!.minBet) {
        AudioApi.play({ type: ISongs.SFX_UI_Hover });
        this.minusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtnHover);
      }
    });
    minusBtn.addListener('mouseout', () => {
      if (this.betAmount > 0 && this.betSettings.bets[this.betAmount - 1]! > this.betSettings!.minBet) {
        this.minusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtn);
      }
    });
    minusBtn.addListener('mousedown', () => {
      if (this.betAmount > 0 && this.betSettings.bets[this.betAmount - 1]! > this.betSettings!.minBet) {
        this.minusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtnPressed);
      }
    });
    minusBtn.addListener('mouseup', () => {
      if (this.betAmount > 0 && this.betSettings.bets[this.betAmount - 1]! > this.betSettings!.minBet) {
        this.minusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtnHover);
      }
    });

    return minusBtn;
  }

  private initPlusBtn(): PIXI.Sprite {
    const plusBtn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtn));
    plusBtn.width = FEATURE_POPUP_PLUS_BTN_WIDTH;
    plusBtn.height = FEATURE_POPUP_PLUS_BTN_HEIGHT;
    plusBtn.y = FEATURE_POPUP_PLUS_BTN_POSITION_Y;
    plusBtn.x = FEATURE_POPUP_PLUS_BTN_POSITION_X;
    plusBtn.anchor.set(0.5, 0);
    plusBtn.interactive = true;
    plusBtn.buttonMode = true;
    plusBtn.on('click', this.handlePlus);
    plusBtn.on('touchstart', this.handlePlus);

    plusBtn.addListener('mouseover', () => {
      if (this.betAmount > 0 && this.betSettings.bets[this.betAmount - 1]! < this.betSettings!.maxBet) {
        AudioApi.play({ type: ISongs.SFX_UI_Hover });
        this.plusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtnHover);
      }
    });
    plusBtn.addListener('mouseout', () => {
      if (this.betAmount > 0 && this.betSettings.bets[this.betAmount - 1]! < this.betSettings!.maxBet) {
        this.plusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtn);
      }
    });
    plusBtn.addListener('mousedown', () => {
      if (this.betAmount > 0 && this.betSettings.bets[this.betAmount - 1]! < this.betSettings!.maxBet) {
        this.plusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtnPressed);
      }
    });
    plusBtn.addListener('mouseup', () => {
      if (this.betAmount > 0 && this.betSettings.bets[this.betAmount - 1]! < this.betSettings!.maxBet) {
        this.plusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtnHover);
      }
    });

    return plusBtn;
  }

  private initInput(): PIXI.Sprite {
    const input = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureInput));
    input.width = FEATURE_POPUP_INPUT_WIDTH;
    input.height = FEATURE_POPUP_INPUT_HEIGHT;
    input.y = FEATURE_POPUP_INPUT_POSITION_Y;
    input.x = FEATURE_POPUP_INPUT_POSITION_X;
    input.anchor.set(0.5, 0);

    return input;
  }

  private initBetValue(): PIXI.Text {
    const betValue = new PIXI.Text(
      `${formatNumber(
        this.currency,
        normalizeCoins(countCoins({ totalAmount: this.getBetValue() })),
        showCurrency(this.currency),
      )}`,
      betValueStyle,
    );
    betValue.resolution = 1;
    betValue.y = FEATURE_POPUP_BET_VALUE_POSITION_Y;
    betValue.x = FEATURE_POPUP_BET_VALUE_POSITION_X;
    betValue.anchor.set(0.5);
    updateTextScale(betValue, this.input.width - 30, 250);

    return betValue;
  }

  private initCancelBtn(): PIXI.Sprite {
    const cancelBtn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtn));
    cancelBtn.y = FEATURE_POPUP_CANCEL_BTN_POSITION_Y;
    cancelBtn.x = FEATURE_POPUP_CANCEL_BTN_POSITION_X;
    cancelBtn.anchor.set(0.5, 0);
    cancelBtn.interactive = true;
    cancelBtn.buttonMode = true;
    cancelBtn.on('click', () => this.onCancel());
    cancelBtn.on('touchstart', () => this.onCancel());

    cancelBtn.addListener('mouseover', () => {
      AudioApi.play({ type: ISongs.SFX_UI_Hover });
      this.cancelBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtnHover);
    });
    cancelBtn.addListener('mouseout', () => {
      this.cancelBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtn);
    });
    cancelBtn.addListener('mousedown', () => {
      this.cancelBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtnPressed);
    });
    cancelBtn.addListener('mouseup', () => {
      this.cancelBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtn);
    });
    return cancelBtn;
  }

  private onCancel() {
    AudioApi.play({ type: ISongs.SFX_UI_Close });
    this.visible = false;
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP, false);
    eventManager.emit(EventTypes.POPUP_BG_VISIBLE, false);
  }

  private initOkBtn(): PIXI.Sprite {
    const okBtn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureOkBtn));
    okBtn.y = FEATURE_POPUP_OK_BTN_POSITION_Y;
    okBtn.x = FEATURE_POPUP_OK_BTN_POSITION_X;
    okBtn.anchor.set(0.5, 0);
    okBtn.interactive = true;
    okBtn.buttonMode = true;
    okBtn.on('click', (): void => this.handleClickOk());
    okBtn.on('touchstart', (): void => this.handleClickOk());

    okBtn.addListener('mouseover', () => {
      if (!this.isNoFunds) {
        AudioApi.play({ type: ISongs.SFX_UI_Hover });
        this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtnHover);
      }
    });
    okBtn.addListener('mouseout', () => {
      if (!this.isNoFunds) {
        this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtn);
      }
    });
    okBtn.addListener('mousedown', () => {
      if (!this.isNoFunds) {
        this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtnPressed);
      }
    });
    okBtn.addListener('mouseup', () => {
      if (!this.isNoFunds) {
        this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtn);
      }
    });

    return okBtn;
  }

  private getBetAmount = (betAmount: number): number => {
    return (
      _.findIndex(this.betSettings!.bets, (bet) => {
        return bet === betAmount / this.linesAmount;
      }) + 1
    );
  };

  private handleMinus = (): void => {
    if (this.betAmount > 1 && this.betSettings.bets[this.betAmount - 1]! > this.betSettings!.minBet) {
      // eslint-disable-next-line no-plusplus
      this.betAmount--;
      setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
      this.updateBets();
      this.handleDisable();
      setBetAmount(setCoinAmount() * setSlotConfig().lineSet.coinAmountMultiplier);
      AudioApi.play({ type: ISongs.SFX_UI_BetChange });
    }
  };

  private handlePlus = (): void => {
    if (this.betSettings.bets[this.betAmount - 1]! < this.betSettings!.maxBet) {
      // eslint-disable-next-line no-plusplus
      this.betAmount++;
      setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
      this.updateBets();
      this.handleDisable();
      setBetAmount(setCoinAmount() * setSlotConfig().lineSet.coinAmountMultiplier);
      AudioApi.play({ type: ISongs.SFX_UI_BetChange });
    }
  };

  private updateBets(): void {
    this.totalCostTextAmount.text = this.getTotalCost();
    this.betValue.text = `${formatNumber(
      this.currency,
      normalizeCoins(countCoins({ totalAmount: this.getBetValue() })),
      showCurrency(this.currency),
    )}`;
    updateTextScale(this.totalCostTextAmount, isMobileDevice() ? 350 : 450, 250);
    updateTextScale(this.betValue, this.input.width - 30, 250);
  }

  private getTotalCost = (): string => {
    return `${formatNumber(
      this.currency,
      normalizeCoins(this.getBetValue() * this.getCoinAmount()),
      showCurrency(this.currency),
    )}`;
  };

  private getBetValue = (): number => {
    return this.linesAmount * (this.betSettings!.bets[this.betAmount - 1] || 1);
  };

  private getCoinAmount = (): number => {
    const bonuses = setBonuses();
    const bonus = _.chain(bonuses)
      .filter((bonus) => bonus.type === FeatureTypes.SPECIAL_ROUND && bonus.id === bonusIds[GameMode.BUY_FEATURE])
      .get(0, {})
      .value() as IBonus;

    return bonus.coinAmount;
  };

  private handleClickOk = (): void => {
    if (!this.isNoFunds) {
      AudioApi.play({ type: ISongs.SFX_UI_General });
      eventManager.emit(
        EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP,
        this.getTotalCost(),
        this.betSettings.bets[this.betAmount]!,
      );
      this.visible = false;
    }
  };

  private handleDisable = (): void => {
    const bet = this.betSettings.bets[this.betAmount - 1];
    if (bet === this.betSettings!.minBet) {
      this.minusBtn.buttonMode = false;
      this.minusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtnDisable);
    } else {
      this.minusBtn.buttonMode = true;
      this.minusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtn);
    }

    if (bet === this.betSettings!.maxBet) {
      this.plusBtn.buttonMode = false;
      this.plusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtnDisable);
    } else {
      this.plusBtn.buttonMode = true;
      this.plusBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtn);
    }

    this.isNoFunds = this.balance < normalizeCoins(this.getBetValue() * this.getCoinAmount());

    if (this.isNoFunds) {
      this.okBtn.buttonMode = false;
      this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtnDisable);
    } else {
      this.okBtn.buttonMode = true;
      this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtn);
    }
  };

  private closeAllAnimationsInSlot() {
    eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
  }
}

export default BuyFeaturePopup;
